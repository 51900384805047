const user_scopes = {
    descriptions: {
        custom_data: '你的自訂資料',
        email: '你的電子郵件地址',
        phone: '你的電話號碼',
        profile: '你的姓名、用戶名、頭像和其他個人資訊',
        roles: '你的角色',
        identities: '你的關聯社交身份',
        'urn:logto:scope:organizations': '你的組織資訊',
        'urn:logto:scope:organization_roles': '你的組織角色',
        address: '你的地址',
    },
};
export default Object.freeze(user_scopes);
