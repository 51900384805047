const guide = {
    start_building: '開始構建',
    get_started: '立即開始',
    categories: {
        featured: '推薦熱門開發框架',
        Traditional: '傳統網頁應用',
        SPA: '單頁應用',
        Native: '原生應用',
        MachineToMachine: '機器對機器',
        Protected: '非 SDK 整合',
        ThirdParty: '第三方應用程式',
        SAML: 'SAML 應用程式',
    },
    filter: {
        title: '篩選框架',
        placeholder: '搜索框架',
    },
    checkout_tutorial: '查看 {{name}} 教程',
    do_not_need_tutorial: '如果你現在不需要教程，可以點擊右側按鈕繼續創建應用',
    finish_and_done: '完成',
    cannot_find_guide: '找不到你想要的教程？',
    describe_guide_looking_for: '描述你正在尋找的教程',
    request_guide_successfully: '請求已成功提交，謝謝！',
    app: {
        select_framework_or_tutorial: '選擇一個框架或教程',
        guide_modal_title: '從 SDK 和指南開始',
        modal_subtitle: '使用我們提供的 SDK 和集成教程加速你的應用開發過程。',
        select_a_framework: '選擇一個框架',
        continue_without_framework: '跳過教程直接創建應用',
        describe_guide_looking_for_placeholder: '例如：我想將 Logto 集成到我的 Angular 應用程式中。',
    },
    api: {
        modal_title: '首先從教程開始',
        modal_subtitle: '使用我們提供的教程來加速你的應用程式開發過程。',
        select_a_tutorial: '選擇一個教程',
        continue_without_tutorial: '跳過教程直接創建 API 資源',
        describe_guide_looking_for_placeholder: '例如：我想使用 deno 框架驗證我的 API。',
    },
};
export default Object.freeze(guide);
