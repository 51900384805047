const application = {
    invalid_type: '僅允許機器對機器應用程式附加角色。',
    role_exists: '該角色 ID {{roleId}} 已被添加至此應用程式。',
    invalid_role_type: '無法將使用者類型的角色指派給機器對機器應用程式。',
    invalid_third_party_application_type: '僅傳統網路應用程式可以標記為第三方應用程式。',
    third_party_application_only: '該功能僅適用於第三方應用程式。',
    user_consent_scopes_not_found: '無效的使用者同意範圍。',
    consent_management_api_scopes_not_allowed: '管理 API 範圍不被允許。',
    protected_app_metadata_is_required: '需要保護應用程式元數據。',
    protected_app_not_configured: '保護應用程式提供者未配置。此功能不適用於開源版本。',
    cloudflare_unknown_error: '在請求 Cloudflare API 時發生未知錯誤',
    protected_application_only: '該功能僅適用於受保護的應用程式。',
    protected_application_misconfigured: '受保護的應用程式配置錯誤。',
    protected_application_subdomain_exists: '受保護應用程式的子域名已被使用。',
    invalid_subdomain: '無效的子域名。',
    custom_domain_not_found: '找不到自訂域名。',
    should_delete_custom_domains_first: '應先刪除自訂域名。',
    no_legacy_secret_found: '該應用程式沒有傳統秘鑰。',
    secret_name_exists: '秘鑰名稱已存在。',
    saml: {
        use_saml_app_api: '使用 `[METHOD] /saml-applications(/.*)?` API 操作 SAML 應用程式。',
        saml_application_only: '該 API 僅適用於 SAML 應用程式。',
        reach_oss_limit: '由於已達到 {{limit}} 的限制，你無法創建更多的 SAML 應用程式。',
        acs_url_binding_not_supported: '僅支持使用 HTTP-POST 方式接收 SAML 聲明。',
        can_not_delete_active_secret: '無法刪除活躍的秘鑰。',
        no_active_secret: '找不到活躍的秘鑰。',
        entity_id_required: '需要提供實體 ID 來生成元數據。',
        name_id_format_required: '需要提供名稱 ID 格式。',
        unsupported_name_id_format: '不支持的名稱 ID 格式。',
        missing_email_address: '使用者沒有電子郵件地址。',
        email_address_unverified: '使用者的電子郵件地址未驗證。',
        invalid_certificate_pem_format: '無效的 PEM 證書格式',
        acs_url_required: '需要提供聲明承載者服務 URL。',
        private_key_required: '需要提供私鑰。',
        certificate_required: '需要提供證書。',
        invalid_saml_request: '無效的 SAML 驗證請求。',
        auth_request_issuer_not_match: 'SAML 驗證請求的發行者與服務提供者的實體 ID 不匹配。',
        sp_initiated_saml_sso_session_not_found_in_cookies: '在 cookie 中找不到服務提供者發起的 SAML 單點登入會話 ID。',
        sp_initiated_saml_sso_session_not_found: '找不到服務提供者發起的 SAML 單點登入會話。',
        state_mismatch: '`state` 不匹配。',
    },
};
export default Object.freeze(application);
